import { AttributeTextValue, AttributeValueBase } from '@msdyn365-commerce/retail-proxy';
import get from 'lodash/get';

export const GetAttributeKeyFromSlot = (slot: React.ReactNode, path: string): string => {
    const value = get(slot, path);
    if (typeof value === 'string') {
        return value;
    }
    return '';
};

export const GetAttributeValue = (
    attributeValues: AttributeValueBase[] | undefined,
    attributeToFind: string | null | undefined
): string | null => {
    if (!attributeToFind) {
        return null;
    }
    const attr = attributeValues?.find(attr => attr.Name === attributeToFind) as AttributeTextValue;
    return attr?.TextValue || null;
};

export const GetAttributeIndex = (attributeValues: AttributeValueBase[], attributeKey: string): number => {
    return attributeValues?.findIndex(attr => attr.Name === attributeKey);
};

export const GetAttributeObject = (attributeKey: string, value: string) => {
    return {
        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
        Name: attributeKey,
        ExtensionProperties: [],
        TextValue: value,
        TextValueTranslations: []
    };
};

export const AddOrUpdateAttribute = (attributeValues: AttributeValueBase[], attributeKey: string, value: string): AttributeValueBase[] => {
    const index = GetAttributeIndex(attributeValues, attributeKey);
    const attributeObj = GetAttributeObject(attributeKey, value) as AttributeTextValue;
    if (index >= 0) {
        attributeValues.splice(index, 1, attributeObj);
    } else {
        attributeValues.push(attributeObj);
    }
    return attributeValues;
};

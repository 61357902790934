import { CommerceProperty } from '@msdyn365-commerce/retail-proxy';
import { CommercePropertyValueEnum } from './CommercePropertyValueEnum';

/**
 * Gets the value for a specific extension property
 * @param properties Array of extension properties
 * @param key The key to find in the extension properties list
 * @param valueType The commercePropertyValue to return
 * @returns The specified value in the chosen extension property or null if nothing found
 */
export const GetExtensionPropertyValueByKey = (
    properties: CommerceProperty[] | undefined,
    key: string,
    valueType: CommercePropertyValueEnum
) => {
    const property = properties?.find(p => p.Key === key)?.Value;

    const val = property ? property[`${valueType}`] : null;
    return val;
};
